.login {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-300);
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(70vh - var(--header-height) - (var(--spacing-300) * 4));
  margin: 0 auto;

  text-align: center;
  text-wrap: pretty;
}
