.background {
  position: fixed;
  inset: 0;
  z-index: -1;

  width: 100vw;
  height: 100vh;
  height: 100dvh;

  overflow: hidden;

  pointer-events: none;

  &__blob {
    position: absolute;
    inset: 0;

    will-change: transform;

    @media screen and (width >= 800px) {
      animation: rotate 45s linear infinite;
    }

    &:first-child > div {
      top: 0;
      right: 0;

      transform: translate(55%, -40%);
    }

    &:last-child > div {
      left: 0;
      bottom: 0;

      transform: translate(-75%, 50%);
    }

    > div {
      position: absolute;

      svg {
        transform: scale(2);
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.75);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}
